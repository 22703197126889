<template>
  <div id="app">
    <vca-loading v-if="loadingFlow" />
    <notifications position="top center" width="100%" />
    <TopNavigation class="desktop-view" />
    <MobileNavigation class="mobile-view" />
    <router-view id="app-content" />
    <Footer id="app-footer" />
  </div>
</template>

<script>
import TopNavigation from "@/components/layout/TopNavigation";
import MobileNavigation from "@/components/layout/MobileNavigation";
import Footer from "@/components/layout/Footer";
import { mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    TopNavigation,
    MobileNavigation,
    Footer,
  },
  data() {
    return {
      languageMapping: { de: "de", ch: "de", gb: "gb" },
    };
  },
  computed: {
    ...mapGetters({
      loadingFlow: "loadingFlow",
      api_lang: "api_lang",
      currentMsg: "currentMsg",
      suppressMsg: "suppressMsg",
    }),
  },
  watch: {
    currentMsg: function (currentMsg) {
      if (currentMsg !== null) {
        if (this.suppressMsg == false) {
          this.notification(currentMsg);
        }
        this.$store.commit("currentMsg", null);
      }
    },
    $route: function () {
      if (this.loadingFlow) {
        this.$store.commit("loadingFlow");
      }
      this.$store.commit("breadCrumb");
    },
    "$i18n.locale": function (value, nVal) {
      if (this.mappedLang(nVal) != this.mappedLang(value)) {
        this.loadApi(value);
      }
    },
  },
  created() {
    if (this.$i18n) {
      if (localStorage.language === undefined) {
        localStorage.language = this.$i18n.locale.toLowerCase();
      } else {
        this.$i18n.locale = localStorage.language;
      }
    }
    if (this.loadingFlow) {
      this.$store.commit("loadingFlow");
    }
    if (this.currentMsg !== null) {
      this.notification(this.currentMsg);
      this.$store.commit("currentMsg", null);
    }
  },
  mounted() {
    if (
      process.env.VUE_APP_GOOGLE_MAPS === undefined ||
      process.env.VUE_APP_GOOGLE_MAPS != false
    ) {
      this.loadApi(this.$i18n.locale ? this.$i18n.locale : "de");
    }
  },
  methods: {
    mappedLang(value) {
      return this.languageMapping[value];
    },
    loadApi(value) {
      this.setAPILanguage(
        this.mappedLang(value),
        ["places"],
        "AIzaSyDc4ozT-5VuxJEs0PJohjno4lysZ1tTtfE"
      ).then(() => {
        this.$store.commit("api_lang", this.mappedLang(value));
      });
    },
    setAPILanguage(lang, libraries, key) {
      //Destroy old API
      document
        .querySelectorAll('script[src^="https://maps.googleapis.com"]')
        .forEach((script) => {
          script.remove();
        });
      if (window.google) delete window.google;

      //Generate new Google Maps API script
      let newAPI = document.createElement("script");
      newAPI.src =
        "https://maps.googleapis.com/maps/api/js?libraries=" +
        libraries.join(",") +
        "&key=" +
        key +
        "&language=" +
        lang +
        "&callback=googleMapsAPILoaded";

      //Callback for the Google Maps API src
      window.googleMapsAPILoaded = () => {
        let event = new CustomEvent("googleMapsAPILoaded");
        window.dispatchEvent(event);
      };

      //Wait for the callback to be executed
      let apiLoaded = new Promise((resolve) => {
        window.addEventListener("googleMapsAPILoaded", () => {
          resolve();
        });
      });

      //Start the script
      document.querySelector("head").appendChild(newAPI);

      return apiLoaded;
    },
  },
};
</script>

<style lang="scss">
.desktop-view {
  display: block !important;
  @include media(small) {
    display: none !important;
  }
}
.mobile-view {
  display: none !important;
  @include media(small) {
    display: contents !important;
  }
}
.mobile-block-view {
  display: none !important;
  @include media(small) {
    display: block !important;
  }
}
#app {
  font-family: "Roboto Slab";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #555555;
  @include media(small) {
    font-size: 0.8em;
  }
}

.vca-page {
  flex: 0 1 100%;
  min-width: 0;
}
.table-container {
  width: 100%;
}
.avatar-container {
  max-width: 100%;
  img {
    max-width: 100%;
  }
}

tr.vca-table-row:nth-child(2n + 3) {
  background-color: $vca-primary-light;
}

.dashlet-content {
  .vca-row {
    flex-wrap: nowrap;
  }
}

.tags-container {
  display: inline-block;
  color: #fff;

  div.tag,
  div.role {
    margin: 5px;
    padding: 5px;
    border-radius: 5px;
    display: inline-block;
    background-color: $vca-primary-dark;
    @include media(large) {
      padding: 10px;
      border-radius: 10px;
    }
  }

  div.tag-label {
    margin: 3px;
    display: inline-block;
  }

  div.confirm {
    background-color: $green;
    cursor: pointer;
    border-radius: 5px;
    display: inline-block;
    margin: auto 0 auto 10px;
    position: relative;
    padding: 0 4px;
    font-weight: bold;
  }

  div.cancel {
    background-color: $vca-secondary-darker;
    cursor: pointer;
    border-radius: 5px;
    display: inline-block;
    margin: auto 0 auto 10px;
    position: relative;
    padding: 0 4px;
    font-weight: bold;
  }

  &.tags-green {
    div.tag,
    div.role {
      background-color: $green;
    }
    div.confirm {
      background-color: $green-light;
    }
    div.cancel {
      background-color: $green-dark;
    }
  }
  &.tags-red {
    div.tag,
    div.role {
      background-color: $red;
    }
    div.confirm {
      background-color: $red-light;
    }
    div.cancel {
      background-color: $red-dark;
    }
  }
  &.tags-blue {
    color: $blue-dark;
    div.tag,
    div.role {
      background-color: $blue-light;
    }
    div.confirm {
      background-color: $light-light-blue;
    }
    div.cancel {
      background-color: $blue;
    }
  }
  &.tags-white {
    color: $blue;
    div.tag,
    div.role {
      border: solid thin $blue;
      background-color: $white;
    }
    div.confirm {
      color: $white;
      border: solid thin $blue;
      background-color: $blue;
    }
    div.cancel {
      color: $white;
      border: solid thin $blue-dark;
      background-color: $blue-dark;
    }
  }
  &.tags-yellow {
    color: $blue-dark;
    div.tag,
    div.role {
      background-color: $yellow;
    }
    div.confirm {
      background-color: $yellow-light;
    }
    div.cancel {
      background-color: $yellow-dark;
    }
  }
  &.tags-orange {
    div.tag,
    div.role {
      background-color: $orange;
    }
    div.confirm {
      background-color: $orange-light;
    }
    div.cancel {
      background-color: $orange-dark;
    }
  }
}

img.editable {
  width: 32px;
  height: 32px;

  &.apply-filter {
    filter: invert(53%) sepia(41%) saturate(6576%) hue-rotate(167deg)
      brightness(87%) contrast(101%);
  }
}

.error {
  .quill-editor {
    border: solid thin $orange;
  }
}

.table-options {
  display: inline-block;
  width: max-content;
}

.table-header-option {
  width: max-content !important;
  margin: 10px !important;
}

.vca-table-check {
  padding: 5px !important;
  display: table-cell;
  height: auto;
  div {
    margin: auto;
  }
}

tr.vca-table-row > td.vca-table-cell,
tr.vca-table-header > th.vca-table-cell {
  text-align: left !important;
}
tr.vca-table-row > td.vca-table-cell ~ td.vca-table-cell,
tr.vca-table-header > th.vca-table-cell ~ th.vca-table-cell {
  text-align: right !important;
}

tr.vca-table-row > td.vca-table-cell .info-box,
tr.vca-table-header > th.vca-table-cell .info-box {
  color: black;
}
.sortable:hover {
  color: $vca-primary-dark;
}

.margin-cancel-input {
  margin: 5px 5px 5px 0 !important;
}

.small-cancel-button {
  button {
    width: 41px !important;
    height: 41px !important;
    @include media(large) {
      width: 48px !important;
      height: 48px !important;
    }
  }
  .cancel-button {
    height: 100%;
  }
}

.margin-cancel-button,
.margin-auto {
  margin: auto;
}

.dropdown-option {
  flex-wrap: wrap;
}

.link,
.clickable {
  cursor: pointer;
  color: $blue;
}

.desktop-options {
  display: inline-flex;
  & > div {
    margin: auto;
  }
}

h3 {
  @include media(small) {
    font-size: 1rem;
    margin: 0.15rem 0 !important;
  }
}
p {
  @include media(small) {
    font-size: 0.9rem;
    line-height: 1.6rem !important;
    margin: 0.25rem 0 !important;
  }
}
.vca-card {
  @include media(small) {
    padding: 10px;
  }
}

.event-card {
  border-radius: 5px;
  height: 100%;
  box-sizing: border-box;
  &.event-concert {
    background-color: $orange-light;
  }
  &.event-festival {
    background-color: $yellow;
  }
  &.event-goldeimer {
    background-color: $orange;
  }
  &.event-nwgathering,
  &.event-regiocamp,
  &.event-crew_meeting,
  &.event-network {
    background-color: $pink;
  }
  &.event-online {
    background-color: $green-dark;
  }
  &.event-stadium {
    background-color: $red-light;
  }
  &.event-other {
    background-color: $light-light-blue;
  }
  .event-title {
    margin-bottom: 10px;
    img {
      max-height: 32px;
    }
  }

  p {
    line-height: 1.5;
    margin: 5px 0 0 0;
  }

  .event-subtitle {
    font-size: 0.75em;
    margin-top: -35px;
    @include media(large) {
      margin-top: -10px;
    }
  }
  .event-card-buttons {
    position: relative;
    & > div {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
.formatted {
  white-space: pre-wrap;
}

.table_participations {
  white-space: nowrap;
}
</style>
